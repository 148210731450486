<template>
  <div v-if="!modeGrid" class="entry">
    <Spinner :isLoading="uploadLoading" :message="loadingMessage"/>
    <h1>{{ $t('entry.title') }}</h1>

    <div v-if="!disabledEntry" class="buttons buttons-align-right">
      <div class="extourne">
        <input id="checkbox" v-model="extourne" type="checkbox" style="pointer-events: none">
        <!--<input type="checkbox" id="checkbox" v-model="extourne" :disabled="selectedFamily.extourne === false">-->
        <label for="extourne">Réaliser une extourne</label>
      </div>
      <v-button class="flat" @click="selectFamily()">
        <v-svg>cancel</v-svg>
        {{ $t('cancel') }}
      </v-button>
      <v-button :disabled="!allIsValid" @click="saveEntries()">
        <v-svg>save</v-svg>
        {{ $t('save') }}
      </v-button>
    </div>

    <div v-else class="buttons buttons-align-right">
      <div style="padding: 12px">
        <span style="margin-bottom:30px; font-weight:bold">Formulaire &nbsp;</span>
        <label class="switch">
          <input type="checkbox" v-model="modeGrid">
          <span class="slider round"></span>
        </label>
        <span style="margin-bottom:30px; font-weight:bold">&nbsp; Tableau &nbsp;</span>
      </div>
      <v-button class="flat" style="margin: 0 5px" @click="goBackToEntries(selectedFormat)">
        <v-svg>cancel</v-svg>
        {{ $t('entry.goBackToEntry') }}
      </v-button>
      <div>
        <input id="file-upload"
               ref="file"
               type="file"
               accept=".csv"
               class="input-file"
               :disabled="lockedEntry"
               @change="checkIfAlreadyUploaded">
      </div>
      <v-button :disabled="lockedEntry" @click="onPickFile">
        <v-svg>import</v-svg>
        <span>{{ $t('button.upload') }}</span>
      </v-button>
    </div>

    <div class="content" :class="{ 'family-selected': !disabledEntry }">
      <section class="family-choice">
        <template v-if="loading">
          <loading/>
        </template>
        <template v-else>
          <div class="select-family">
            <div>
              <h2>{{ selectedFormat }}</h2>
            </div>
          </div>
          <div v-if="lockedEntry" class="locked-entry">
            {{ $t('entry.messageLockedEntry') }}
          </div>
          <div v-if="!lockedEntry" class="families">
            <button v-for="(family, index) in familiesFiltered" :key="index" class="family"
                    @click="selectFamily(family)">
              <div class="code">{{ family.code }}</div>
              <div class="description">{{ family.description }}</div>
            </button>
          </div>
        </template>
      </section>
      <section class="entries" :class="{ disabled: disabledEntry }">
        <h2>Saisie des Démarques</h2>
        <div class="details">
          <div><span class="label">{{ $t('date') }}:</span> {{ date.toFormat('dd/MM/yyyy') }}</div>
          <div><span class="label">{{ $t('user') }}:</span> {{ userCode }}</div>
          <div>
            <span class="label">{{ $t('family') }}:</span>
            {{ !disabledEntry ? `${selectedFamily.code} : ${selectedFamily.description}` : `Vous n'avez pas encore
            selectionné de famille` }}
          </div>
          <div>
            <span class="label">{{ $t('entry.totalAmount') }} </span> {{ amountTotal }}
          </div>
          <div v-if="!allIsValid" class="error-message">
            {{ $t('entry.error') }}
            <span v-if="isAmountZero && amountTotal > 0">
              <br>
              {{ $t('entry.errorAmount') }}
            </span>
          </div>
        </div>
        <div v-if="entries.length" class="grid">
          <div />
          <div v-for="name in Object.keys(this.entries[0])" :key="`header#${name}`" class="header cell">{{ $t(name) }}</div>
          <div />
          <template v-for="(vEntry, i) in $v.entries.$each.$iter">
            <div :key="`delete#${i}`" class="cell">
              <v-button tabindex="-1" class=" flat" @click="removeLine(i)"><v-svg>cancel</v-svg></v-button>
            </div>
            <div :key="`uc#${i}`" class="cell ">
              <input
                v-model="vEntry.$model.accountingUnit"
                v-tooltip.bottom-start="vEntry.accountingUnit.$invalid ? 'UC non valide' : ''"
                :list="`input-uc#${i}`"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.accountingUnit.$invalid }"
                placeholder="ex: FRH002A"
                type="text"
                @click="activeLine = i">
              <datalist :id="`input-uc#${i}`">
                <option
                  v-for="uc in validAccountingUnits(vEntry.$model.siteId)"
                  :key="`option-uc#${i}:${uc}`"
                  :value="uc">
                  {{ uc }}
                </option>
              </datalist>
            </div>
            <div :key="`site#${i}`" class="cell">
              <input
                v-model="vEntry.$model.siteId"
                v-tooltip.bottom-start="vEntry.siteId.$invalid ? 'Site non valide' : ''"
                :list="`sites`"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.siteId.$invalid }"
                placeholder="ex: 0002"
                type="text"
                @focus="fillDataListIfEmpty(`sites`)"
                @input="completeDataList($event, `sites`)"
                @click="activeLine = i">
              <datalist :id="`sites`">
              </datalist>
            </div>

            <div :key="`cr#${i}`" class="cell ">
              <input
                v-model="vEntry.$model.resultCenterId"
                v-tooltip.bottom-start="vEntry.resultCenterId.$invalid ? 'CR non valide' : ''"
                :list="`crs`"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.resultCenterId.$invalid }"
                placeholder="ex: 0002"
                type="number"
                @focus="fillDataListIfEmpty('crs')"
                @input="completeDataList($event, 'crs')"
                @click="activeLine = i">
              <datalist :id="`crs`">
                <!-- <option
                  v-for="cr in validResultCenterIds()"
                  :key="`option-cr#${i}:${cr}`"
                  :value="cr">
                  {{ cr }}
                </option> -->
              </datalist>
            </div>

            <div :key="`crlabel#${i}`" class="cell ">
              <span>
                {{ getResultCenterLabel(vEntry.$model.resultCenterId) }}
              </span>
            </div>

            <div :key="`amount#${i}`" class="cell">
              <input
                v-model="vEntry.$model.amount"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.amount.$invalid }"
                placeholder="ex: -222"
                type="number"
                step='0.001'
                @blur="showModalIfAboveThreshold(vEntry.$model.amount)"
                @click="activeLine = i">
            </div>

            <div :key="`label#${i}`" class="cell">
              <input
                v-model="vEntry.$model.label"
                maxlength="40"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.label.$invalid }"
                placeholder="ex: Écart entre Pilot et Hyperion"
                type="text"
                @click="activeLine = i">
            </div>

            <div v-if="selectedFamily" class="cell" :key="`buttons#${i}`">
              <v-button class="flat" @click="newLine"><v-svg>plus</v-svg></v-button>
            </div>
          </template>
        </div>

      </section>
    </div>

    <v-modal v-if="showModal">
      <h3 slot="header">{{ $t('entry.modal.warning') }} !</h3>
      <span slot="body">{{ warningMessage }} </span>
      <v-button slot="footer" class="small" @click="showModal=false">{{ $t('entry.modal.continue') }}</v-button>
    </v-modal>

    <v-modal v-if="showConfirmModal">
      <h3 slot="header">{{ $t('entry.modal.warning') }} !</h3>
      <span slot="body">{{ $t('entry.modal.fileMessage') }} </span>
      <v-button slot="footer" class="small" @click="upload(true)">{{ $t('entry.modal.continue') }}</v-button>
      <v-button slot="footer" class="small" @click="closeConfirmModal">{{ $t('entry.modal.cancel') }}</v-button>
    </v-modal>

    <v-modal v-if="showErrorModal">
      <h3 slot="header">Erreur lors de l'enregistrement du fichier</h3>
      <div style="max-height: 400px;overflow-x: scroll" slot="body">
        <div v-html="errorMessages.join('')"></div>
      </div>
      <v-button slot="footer" class="small" @click="showErrorModal=false">{{ $t('close') }}</v-button>
    </v-modal>
  </div>
  <div v-else class="entry">
    <Spinner :isLoading="uploadLoading" :message="loadingMessage"/>
    <div class="buttons buttons-align-right">
      <div style="padding: 12px">
        <span style="margin-bottom:30px; font-weight:bold">Formulaire &nbsp;</span>
        <label class="switch">
          <input type="checkbox" v-model="modeGrid">
          <span class="slider round"></span>
        </label>
        <span style="margin-bottom:30px; font-weight:bold">&nbsp; Tableau &nbsp;</span>
      </div>
      <v-button class="flat" style="margin: 0 5px" @click="goBackToEntries(selectedFormat)">
        <v-svg>cancel</v-svg>
        {{ $t('entry.goBackToEntry') }}
      </v-button>
      <div>
        <input id="file-upload"
               ref="file"
               type="file"
               accept=".csv"
               class="input-file"
               :disabled="lockedEntry"
               @change="checkIfAlreadyUploaded"/>
      </div>
      <v-button :disabled="lockedEntry" @click="onPickFile">
        <v-svg>import</v-svg>
        <span>{{ $t('button.upload') }}</span>
      </v-button>
    </div>
    <h1>Etat des marges entrepôt ELNA</h1>
    <div class="details">
      <div><span class="label">{{ $t('date') }}:</span> {{ date.toFormat('dd/MM/yyyy') }}</div>
      <div><span class="label">{{ $t('imputationDate') }}:</span> <span class="input-period">{{ $t("frenchMonths")[currentClosureDate.month - 1 ] + ' ' +currentClosureDate.year.toString() }}</span></div>
      <div><span class="label">{{ $t('user') }}:</span> {{ userCode }}</div>
      <div>
        <span class="label">{{ $t('siteId') }}: </span>
        <select v-model="siteSelected"
                style="width:100px;"
                @change="onChange($event)">
          <option v-for="option in options" v-bind:value="option">
            {{ option }}
          </option>
        </select>
        <button style="float:right" :disabled="lockedEntry" @click="onShowModalFiltreColumns">
          <v-svg>import</v-svg>
          <span>Filtre des colonnes </span>
        </button>
      </div>
    </div>
    <div style="height: 80%; width: 100%;float: left">
      <ag-grid-vue style="height: 100%;"
                   class="ag-theme-alpine"
                   :columnDefs="columnDefs"
                   @grid-ready="onGridReady"
                   :defaultColDef="defaultColDef"
                   :rowData="rowData"
                   @cell-value-changed="onCellValueChanged">
      </ag-grid-vue>
    </div>
    <v-modal class="toto" v-if="showModal">
      <h3 slot="header">{{ $t('restitution.title') }}</h3>
      <div style="max-height: 400px;overflow-x: scroll" slot="body">
        <GridEntries :entries="slicedEntries()" :headers="headers" />
      </div>
      <v-button slot="footer" class="small" @click="showModal=false">{{ $t('close') }}</v-button>
    </v-modal>
    <v-modal class="toto" v-if="showModalFiltreColumns">
      <h3 slot="header">Filtre des colonnnes</h3>
      <div style="max-height: 400px;overflow-x: scroll" slot="body">
        <div id="app">
          <div>
            <table>
              <tr>
                <th>Select All<input type="checkbox" @change="selectAll" v-model="allSelected"></th>
              </tr>
              <tr v-for="col in columnDefs">
                <td v-if="col.field != 'family'">{{ col.headerName }}</td>
                <td v-if="col.field != 'family'"><input type="checkbox" v-model="colIds" @click="select" :value="col.field"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <v-button slot="footer" class="small" @click="closeModel">{{ $t('close') }}</v-button>
    </v-modal>

    <v-modal v-if="showErrorModal">
      <h3 slot="header">Erreur lors de l'enregistrement du fichier</h3>
      <div style="max-height: 400px;overflow-x: scroll" slot="body">
        <div v-html="errorMessages.join('')"></div>
      </div>
      <v-button slot="footer" class="small" @click="showErrorModal=false">{{ $t('close') }}</v-button>
    </v-modal>
  </div>

</template>

<script>
  import Vue from 'vue'
  import {mapActions, mapState, mapGetters} from 'vuex'
  import {required} from 'vuelidate/lib/validators'
  import { blockedEntry, formatAmount, pad } from '../utils/utils'
  import {DateTime} from 'luxon'
  import {importCsv} from '@/utils/csv'
  import Spinner from '@/components/base/Spinner'
  import 'ag-grid-community/dist/styles/ag-grid.css'
  import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
  import {AgGridVue} from 'ag-grid-vue'
  import NumericEditor from "../components/grid/numericEditor.vue"
  import CellRenderer from "../components/grid/cellRenderer.vue"
  import GridEntries from "@/components/GridEntries";
  import i18n from "@/i18n";
  import extourne from '@/mixins/extourneSuper'

  const emptyLine = {accountingUnit: '', siteId: '', resultCenterId: '', resultCenterLabel: '', amount: '', label: ''}

  export default {
    name: 'EntryLogistique',
    components: {
      Spinner,
      'ag-grid-vue': AgGridVue,
      numericEditor: NumericEditor,
      cellRenderer: CellRenderer,
      GridEntries
    },
    mixins: [extourne],
    data() {
      return {
        allData: [],
        modeGrid: true,
        columnDefs: [],
        gridApi: null,
        columnApi: null,
        familiesFiltered: [],
        defaultColDef: {
          editable: true,
        },
        siteSelected: '',
        rowData: null,
        getRowClass: null,
        loading: false,
        uploadLoading: false,
        loadingMessage: '',
        selectedFamily: undefined,
        date: DateTime.local(),
        entries: [],
        activeLine: 0,
        userCode: this.$userInfos.uid,
        file: null,
        showModal: false,
        showModalFiltreColumns: false,
        showConfirmModal: false,
        showErrorModal: false,
        selectedFormat: 'LOGSUPER',
        defaultFormat: [],
        haveToDelete: false,
        today: new Date(),
        warningMessage: '',
        extourne: false,
        errorMessages: [],
        options: [],
        start: 0,
        rowCount: '',
        dataByCell: [],
        selected: [],
        allSelected: false,
        colIds: [],
        headers: ['user', 'date', 'imputationDate', 'accountingUnit', 'siteId', 'resultCenterId', 'family', 'amount', 'label', 'entryType']
      }
    },
    computed: {
      ...mapGetters('configuration', ['currentClosureDate', 'formattedFamilies']),
      ...mapGetters('entries', ['allEntries']),
      ...mapState('configuration', ['habilitations', 'families', 'validIndicators', 'formats', 'alertThreshold']),
      lockedEntry() {
        return this.isUserCDG ? false : blockedEntry(this.currentClosureDate, this.selectedFormat)
      },
      disabledEntry() {
        return !this.selectedFamily
      },
      fileName() {
        return this.file && this.file.name.split('.')[0]
      },
      imputationDate() {
        return this.currentClosureDate.year.toString() + this.currentClosureDate.month.toString().padStart(2, '0')
      },
      entriesForStore() {

        return this.entries.reduce((acc, entry) => {
          acc.push({
            accountingUnit: entry.accountingUnit,
            format: this.selectedFormat,
            site: entry.siteId,
            resultCenter: parseInt(entry.resultCenterId),
            label: entry.label,
            amount: entry.amount ? parseFloat(entry.amount) : 0,
            inputDate: this.today,
            imputationDate: this.imputationDate,
            family: this.selectedFamily.code,
            familyAccount: this.selectedFamily.account,
            familyRubric: this.selectedFamily.rubrique,
            user: this.userCode,
            entryType: this.$t('manual')
          })
          return acc
        }, [])
      },
      entriesExtourne() {
        return ![7,14].includes(this.currentClosureDate.month) && this.extourne ? this.entriesForStore.map(a => {
          return {
            ...a,
            amount: -a.amount,
            entryType: this.$t('entry.extourne'),
            imputationDate: this.nextImputationDate
          }
        }) : []
      },
      amountTotal() {
        return this.entries.map(e => e.amount ? parseInt(e.amount) : 0).reduce((a, b) => a + b, 0)
      },
      isAmountZero() {
        return this.selectedFamily && this.selectedFamily.isAmountZero
      },
      allIsValid() {
        if (this.isAmountZero) {
          return !this.$v.entries.$invalid && this.amountTotal === 0
        } else {
          return !this.$v.entries.$invalid
        }
      },

    },
    watch: {
      selectedFamily(val) {
        if (val && this.validAccountingUnits('').length === 1) {
          this.$v.entries.$each.$iter[0].$model.accountingUnit = this.validAccountingUnits('')[0]
        }

        if (val && this.validSiteIds(this.$v.entries.$each.$iter[0].$model.accountingUnit).length === 1) {
          this.$v.entries.$each.$iter[0].$model.siteId = this.validSiteIds(this.$v.entries.$each.$iter[0].$model.accountingUnit)[0]
        }

        this.extourne = val && val.extourne === false ? false : true
      }
    },
    mounted() {
      this.defaultFormat = this.habilitations.formatType
      this.selectedFormat = this.$route.query.format
      this.options = this.validSiteIds()
      this.siteSelected = this.options[0]
      this.columnDefs = this.generateColumns(this.validResultCenterIds(this.siteSelected))
    },
    async created() {
      this.selectedFormat = this.$route.query.format
      this.familiesFiltered = this.filteredFamiliesByType(this.formattedFamilies[this.selectedFormat], '0006-LOGSUPER')
      this.getRowClass = params => {
        if (params.node.rowIndex % 2 != 0) {
          return 'gray-effect-rows'
        }
      }
    },

    methods: {
      ...mapActions('entries', ['insertEntries', 'deleteAndInsertEntries', 'fetchEntriesByType', 'fetchEntriesByParams', 'fetchEntries']),
      async onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        const updateData = (data) => params.api.setRowData(data)
        this.allData = await this.fetchEntriesByParams({
          site: this.siteSelected,
          format: this.selectedFormat.split(),
        })
        updateData(this.generateRows(this.siteSelected, '0006-LOGSUPER'))
        /*fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
           .then((resp) => resp.json())
           .then((data) => updateData(data));*/
      },

      generateRows(selectedSiteId, familyAccount) {
        let dataRows = []
        for (const family of this.familiesFiltered) {
          let concatRow = {}
          let mappedFamily = {"family": family.code}
          for (const CR of this.validResultCenterIds(selectedSiteId)) {
            let mappedCR = {[CR]: this.getTotalAmount(selectedSiteId, family, CR, familyAccount, this.allData)}
            concatRow = Object.assign(concatRow, mappedCR)
          }
          concatRow = Object.assign(mappedFamily, concatRow)
          dataRows.push(concatRow)
        }
        return dataRows;
      },

      getTotalAmount(selectedSiteId, familyCode, resultCenter, familyAccount, entriesData) {
        let totalAmount = 0
        for (const entry of entriesData) {
          if (selectedSiteId === entry.site
            && familyCode === entry.family
            && resultCenter === entry.resultCenter.toString()
            && familyAccount === entry.familyAccount.toString()
            && this.imputationDate === entry.imputationDate
          ) {
            totalAmount += entry.amount
          }
        }
        return totalAmount
      },

      getfilteredEntries(selectedSiteId, familyCode, resultCenter, familyAccount, entriesData) {
        let entries = []
        for (const entry of entriesData) {
          if (selectedSiteId === entry.site
            && familyCode === entry.family
            && resultCenter === entry.resultCenter.toString()
            && familyAccount === entry.familyAccount.toString()
             && this.imputationDate === entry.imputationDate
          ) {
            entries.push(entry)
          }
        }
        return entries
      },

      generateColumns(data) {
        let columnDefinitions = [];
        let _this = this
        columnDefinitions.push({
          headerName: 'Rubrique',
          pinned: 'left',
          field: 'family',
          width: 235,
          editable: false,
          cellStyle: {'font-weight': 'bold'}
        })
          data.map(object => {
            let mappedColumn = {
              headerName: object +' - '+ this.getResultCenterLabel(object),
              field: object,
              width: 170,//12 * object.description.length,
              cellStyle: { 'border-right': '1px solid lightgray'},
              cellEditor: "numericEditor",
              cellRenderer: "cellRenderer",
              cellRendererParams: {
                siteId: this.siteSelected,
                familyAccount: '0006-LOGSUPER',
                clicked: function(params) {
                  const family = params.data.family
                  _this.dataByCell = _this.getfilteredEntries(_this.siteSelected, family, params.colDef.field, params.familyAccount, _this.allData)
                  _this.showModal = true
                }
              }
            }
            columnDefinitions.push(mappedColumn)
          })
        //Remove duplicate columns
        columnDefinitions = columnDefinitions.filter((column, index, self) =>
          index === self.findIndex((colAtIndex) => (
            colAtIndex.field === column.field
          ))
        )
        return columnDefinitions;
      },

      async onChange(event) {
        this.siteSelected = event.target.value
        this.allData = await this.fetchEntriesByParams({
          site: this.siteSelected,
          format: this.selectedFormat.split(),
        })
        this.gridApi.setRowData(this.generateRows(this.siteSelected, '0006-LOGSUPER'))
      },

      async onCellValueChanged(event) {
        if (event.value) {
          const familyCode = event.data.family
          const amount = event.value
          const CR = event.colDef.field
          await this.insertEntries(this.entryToAdd(CR, amount, '0006-LOGSUPER', familyCode).map(entry => {
            return entry
          })).then(async() => {
            this.allData = await this.fetchEntriesByParams({
              site: this.siteSelected,
              format: this.selectedFormat.split(),
            })
          })
        }
        this.gridApi.setRowData(this.generateRows(this.siteSelected, '0006-LOGSUPER'))
      },

      entryToAdd(resultCenterId, amount, familyAccount, familyCode) {
        return [{
          accountingUnit: 'DEFAULT',
          format: this.selectedFormat,
          site: this.siteSelected,
          resultCenter: parseInt(resultCenterId),
          label: this.getResultCenterLabel(resultCenterId),
          amount: amount ? parseFloat(amount) : 0,
          inputDate: this.today,
          imputationDate: this.imputationDate,
          family: familyCode,
          familyAccount: familyAccount,
          familyRubric: familyCode,
          user: this.userCode,
          entryType: this.$t('manual_grid')
        }]
      },

      FORMAT_DATE(date){
        return `${i18n.t("frenchMonths")[parseInt(date.substring(4, 6)) - 1]} ${date.substring(0, 4)}`
      },

      slicedEntries() {
        let searchedEntries = []
        searchedEntries = this.dataByCell.map(entry => {
          return {
            user: entry.user,
            date: entry.inputDate,
            imputationDate: this.FORMAT_DATE(entry.imputationDate),
            accountingUnit: entry.accountingUnit,
            resultCenterId: entry.resultCenter.toString() + '- ' + this.getResultCenterLabel(entry.resultCenter.toString()),
            siteId: entry.site,
            family: entry.family,
            amount: entry.amount,
            label: entry.label,
            format: entry.format,
            entryType: entry.entryType ? entry.entryType : i18n.t('manual')
          }
        })
        searchedEntries = searchedEntries.filter(entry => Object.values(entry))
        searchedEntries = [...new Set(searchedEntries.flat())].sort((a, b) => b.date.seconds - a.date.seconds)
        return searchedEntries && searchedEntries.slice(this.start, this.start + searchedEntries.length)
      },

      completeDataList(e, id) {
        const dlOptions = id === 'sites' ? this.validSiteIds(this.$v.entries.$each.$iter[0].accountingUnit.$model).map(o => {
          return [`<option value="${o}">${this.getSiteLabel(o)}</option>`, o.toLowerCase()]
        }) : this.validResultCenterIds(this.$v.entries.$each.$iter[0].siteId.$model).map(o => {
          return [`<option value="${o}">${this.getResultCenterLabel(o)}</option>`, o.toLowerCase()]
        })
        const fill = val => document.getElementById(id).innerHTML = val
        if (!e.target.value) {
          fill(dlOptions.reduce((sum, [html]) => sum + html, ''))
        } else if (!(e instanceof InputEvent)) { // OR: else if(!e.inputType)
          e.target.blur()
        } else {
          const inputValue = e.target.value.toLowerCase()
          let result = ''
          for (const [html, valuePattern] of dlOptions) {
            if (!valuePattern.indexOf(inputValue)) {
              result += html
            } else if (result) {
              break
            }
          }
          fill(result)
        }
      },

      fillDataListIfEmpty(id) {
        if (!document.getElementById(id).innerHTML) {
          this.completeDataList({target: {}}, id)
        }
      },

      validAccountingUnits(siteId) {
        return [
          ...new Set(
            this.validIndicators[this.selectedFormat].UCSites
              .filter(UCSites => (!siteId.length || siteId === UCSites.SITE_ORACLE))
              .map(UCSites => UCSites.UC_ORACLE)
          )
        ].sort()
      },

      validSiteIds(accountingUnit) {
        return [
          ...new Set(
            this.validIndicators[this.selectedFormat].UCSites
              .filter(UCSites => (!accountingUnit?.length || accountingUnit === UCSites.UC_ORACLE) && (!this.habilitations.site.length || this.habilitations.site.includes(UCSites.SITE_ORACLE)))
              .map(UCSites => UCSites.SITE_ORACLE)
          )
        ].sort()
      },

      validResultCenterIds() {
        return [
          ...new Set(
            this.validIndicators[this.selectedFormat].CRs
              .map(CRS => CRS.CR.toString())
          )
        ]
          .map(uc => uc.length === 4 ? '0' + uc : uc)
          .sort()
      },

      getResultCenterLabel(resultCenterID) {
        return resultCenterID && this.validResultCenterIds().includes(resultCenterID)
          ? this.validIndicators[this.selectedFormat].CRs.find(CR => CR.CR.toString() === resultCenterID).LibelleCR
          : ''
      },

      selectFamily(family) {
        this.selectedFamily = family
        if (!family) this.entries = []
        else this.newLine()
      },

      newLine() {
        const lastLine = this.entries[this.entries.length - 1]
        this.entries.push(
          lastLine
            ? {
              ...emptyLine,
              accountingUnit: lastLine.accountingUnit,
              siteId: lastLine.siteId,
              resultCenterId: lastLine.resultCenterId,
              resultCenterLabel: lastLine.resultCenterLabel,
              label: this.selectedFamily.description
            }
            : {...emptyLine, label: this.selectedFamily.description}
        )
        this.activeLine = this.entries.length - 1
      },

      removeLine(i) {
        this.entries.splice(i, 1)
        if (!this.entries.length) {
          this.entries = [{...emptyLine}]
        }
      },

      saveEntries() {
        this.insertEntries(this.entriesForStore.concat(this.entriesExtourne)).then(() => this.selectFamily())
      },

      showModalIfAboveThreshold(amount) {
        if (Math.abs(amount) >= this.alertThreshold) {
          this.showModal = true
          this.warningMessage = this.$t('entry.modal.alertThresholdMessage') + new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR'
          }).format(Number(this.alertThreshold))
        }
      },
      onPickFile() {
        this.$refs.file.click()
      },
      onShowModalFiltreColumns() {
        this.showModalFiltreColumns = true;
      },
      closeModel() {
        this.showModal = false;
        this.showModalFiltreColumns = false;
      },
      closeConfirmModal() {
        this.showConfirmModal = false
        this.uploadLoading = false
      },
      async checkIfAlreadyUploaded(e) {
        this.uploadLoading = true
        this.loadingMessage = 'Vérification du fichier...'
        this.file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0]
        const alreadyUploaded = await this.fetchEntriesByType(this.fileName)
        if (alreadyUploaded) this.showConfirmModal = true
        else this.upload(false)
      },
      upload(haveToDelete) {
        this.closeConfirmModal()
        this.haveToDelete = haveToDelete
        const reader = new FileReader()
        reader.onload = r => {
          const formattedCSV = importCsv(r.target.result)
          if (formattedCSV[0].CodeFamilleSEM && formattedCSV[0].Libelle && formattedCSV[0].UC && formattedCSV[0].Site && formattedCSV[0].CR && formattedCSV[0].Montant && formattedCSV[0].Format) {
            this.checkFile(formattedCSV, this.fileName)
          } else {
            Vue.toasted.error(`Erreur lors de l'enregistrement du fichier</br> ${this.$t('restitution.error.file')}`)
          }
        }
        reader.readAsText(this.file)
      },
      async checkFile(entries, fileName) {
        this.errorMessages =  []
        let allAmountZero = {}
        this.uploadLoading = true
        let formatsListAll = this.formats.map(format => format.toUpperCase())

        entries.forEach((entry, index) => {
          if (this.checkProperties(entry)) {
            entry.Montant = formatAmount(entry.Montant)
            const formatOk = (formatsListAll.includes(entry.Format.toUpperCase()) && this.selectedFormat.toUpperCase() === entry.Format.toUpperCase())
            const familyOk = this.families.some(f => f.code.normalize() === entry.CodeFamilleSEM.normalize() && entry.Format.toUpperCase() === f.formatType.toUpperCase())
            const UCSiteOk = this.validIndicators[entry.Format.toUpperCase()].UCSites.some(UCSite => entry.UC === UCSite.UC_ORACLE && entry.Site === UCSite.SITE_ORACLE)
            const CROk = this.validIndicators[entry.Format.toUpperCase()].CRs.some(CR => entry.CR === CR.CR.toString())
            const amountOk = !isNaN(entry.Montant)
            const isAmountZero = this.families.find(f => f.code.normalize() === entry.CodeFamilleSEM.normalize() && f.formatType.normalize() === entry.Format.normalize() && f.isAmountZero === true)

            if (amountOk && isAmountZero) {
              allAmountZero[isAmountZero.code.normalize()] = allAmountZero && allAmountZero[isAmountZero.code.normalize()]
                ? allAmountZero[isAmountZero.code.normalize()] + entry.Montant
                : entry.Montant
            }
            if (!formatOk) this.errorMessages.push(this.$t('restitution.error.format', {line: index + 1}))
            if (!familyOk) this.errorMessages.push(this.$t('restitution.error.family', {line: index + 1}))
            if (!UCSiteOk) this.errorMessages.push(this.$t('restitution.error.UCSite', {line: index + 1}))
            if (!CROk) this.errorMessages.push(this.$t('restitution.error.CR', {line: index + 1}))
            if (!amountOk) this.errorMessages.push(this.$t('restitution.error.amount', {line: index + 1}))
          } else {
            this.errorMessages.push(this.$t('restitution.error.lineInError', {line: index + 1}))
          }
        })
        const amountZeroOk = []
        this.loadingMessage = 'Importation du fichier...'
        for (const [key, value] of Object.entries(allAmountZero)) {
          if (value !== 0) amountZeroOk.push(key)
        }
        if (amountZeroOk.length) this.errorMessages.push('Les familles suivantes doivent avoir la somme des montants égal à zéro : ' + amountZeroOk.join(', '))

        if (this.errorMessages.length) {
          this.showErrorModal = true
          //Vue.toasted.error(`Erreur lors de l'enregistrement du fichier</br> ${this.errorMessages.join()}`)
        }else {
          this.loadingMessage = 'Sauvegarde du fichier...'
          const formattedEntries = entries.map(e => {
            ``
            return {
              accountingUnit: e.UC,
              format: e.Format,
              site: this.validIndicators[e.Format.toUpperCase()].UCSites.find(UCSite => e.UC === UCSite.UC_ORACLE && e.Site === UCSite.SITE_ORACLE).SITE_ORACLE,
              resultCenter: parseInt(e.CR),
              label: e.Libelle,
              amount: parseFloat(e.Montant),
              inputDate: this.today,
              imputationDate: this.imputationDate,
              family: e.CodeFamilleSEM,
              familyAccount: this.families.find(f => f.code === e.CodeFamilleSEM && f.formatType.toUpperCase() === e.Format.toUpperCase()).account,
              familyRubric: this.families.find(f => f.code === e.CodeFamilleSEM && f.formatType.toUpperCase() === e.Format.toUpperCase()).rubrique,
              user: this.$userInfos.uid,
              entryType: fileName
            }
          })

          this.haveToDelete
            ? await this.deleteAndInsertEntries({entries: formattedEntries, file_name: this.fileName})
            : await this.insertEntries(formattedEntries)
        }
        this.uploadLoading = false
        this.file = null
      },

      checkProperties(obj) {
        for (let key in obj) {
          if (obj[key] !== null && obj[key] != "")
            return true
        }
        return false
      },

      filteredFamiliesByType(familyArray, filterParam) {
        return familyArray.filter(family => family.account === filterParam)
      },

      selectAll() {
        this.colIds = []
        if (this.allSelected) {
          for (const col of this.columnDefs) {
            if(col.field != 'family'){
              this.colIds.push(col.field)
            }
          }
        }
      },

      select() {
        this.allSelected = false
      }
    },
    validations: {
      entries: {
        $each: {
          accountingUnit: {
            isValid: function (value, entry) {
              return this.validAccountingUnits(entry.siteId).includes(value)
            }
          },
          siteId: {
            isValid: function (value, entry) {
              return this.validSiteIds(entry.accountingUnit).includes(value)
            }
          },
          resultCenterId: {
            isValid: function (value) {
              return this.validResultCenterIds().includes(value)
            }
          },
          amount: {
            isValid: function (value, entry) {
              return !isNaN(Number(entry.amount))
            }
          },
          label: {
            required
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .input-file {
    display: none;
  }

  .select-family > div{
    display: flex;
    align-items: center;
    flex-direction: row;
    h2{
      flex: 1;
    }

    select {
      border: 1px solid #707070;
      border-radius: 3px;
      padding: 5px 8px;
    }
    select:focus{
      outline: none;
    }
  }

  h2 {
    @extend %font-bold;
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
  }

  .buttons {
    position: absolute;
    display: flex;
    margin-bottom: 4rem;

    &-align-right {
      flex-direction: row;
      top: 6.4rem;
      right: 6.4rem;
    }
  }

  .entry {
    overflow: hidden;
    position: relative;

    .content {
      display: flex;
      position: absolute;
      transition: 500ms;
      left: 6.4rem;

      &.family-selected {
        left: calc(-50vw + 6.4rem);
      }
    }

    .family-choice {
      width: 50vw;
      padding-right: 8rem;

      .families {
        max-height: calc(100vh - 21rem);
        overflow-y: scroll;

        button.family {
          display: flex;
          align-items: center;
          cursor: pointer;
          height: 6.4rem;
          width: 98%;
          border-radius: 16px;
          margin-bottom: 2rem;
          background-color: $white;
          outline: none;
          border: 1px solid $dark-grey;

          .code {
            @extend %font-bold;
            width: 20rem;
          }
        }
        button.family-Stock {
          display: flex;
          align-items: center;
          cursor: pointer;
          height: 6.4rem;
          width: 98%;
          border-radius: 16px;
          margin-bottom: 2rem;
          background-color: $light-grey;
          outline: none;
          border: 1px solid $dark-grey;

          .code {
            @extend %font-bold;
            width: 20rem;
          }
        }
      }
    }

    .entries {
      max-height: calc(100vh - 13rem);
      width: calc(100vw - #{$sidebar-width});
      overflow-y: scroll;

      &.disabled {
        opacity: 0.5;

        * {
          cursor: not-allowed;
        }
      }

      .details {
        height: 6em;

        div {
          margin-bottom: 0.4rem;
        }

        .label {
          @extend %font-bold;
        }

        .error-message {
          color: red;
        }
      }

      .grid {
        /*max-height: calc(100% - 250px);*/
        overflow: auto;
        margin: 3.2rem 0;
        display: grid;
        width: 100%;
        margin-top: 4rem;
        grid-template-columns: 70px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        .cell {
          height: 4.8rem;
          @include flex(center, flex-start);
          position: sticky;
          top: 0;
          outline: none;

          &:not(.header) {
            border-bottom: 1px solid $light-grey;
          }
          &:nth-last-child(-n + 8) {
            border: none;
          }

          input {
            border: transparent;
            width: 80%;
            background: none;
            outline: none;
            height: 3.2rem;

            &.active-line {
              border-bottom: 1px solid $light-grey;
            }

            &:focus {
              border-bottom: 2px solid $purple;
            }

            &.error,
            &:not(:valid) {
              border-bottom: 2px solid red;
            }
          }

          .error-message {
            position: absolute;
            font-size: 13px;
            bottom: -1em;
            width: auto;
          }
        }

        .header {
          @extend %font-bold;
          z-index: 2;
          font-size: 2rem;
          background-color: $background;
        }
      }
    }
  }

  .locked-entry {
    font-size: 16px;
    font-weight: bold;
  }

  .extourne {
    display: flex;
    align-items: center;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8f6c9f;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #8f6c9f;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #8f6c9f;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .custom-select {
    position: relative;
    font-family: Arial;
  }

  .custom-select select {
    display: none; /*hide original SELECT element: */
  }

  .select-selected {
    background-color: DodgerBlue;
  }

  /* Style the arrow inside the select element: */
  .select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
  }

  /* Point the arrow upwards when the select box is open (active): */
  .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
  }

  /* style the items (options), including the selected item: */
  .select-items div, .select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
  }

  /* Style items (options): */
  .select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }

  /* Hide the items when the select box is closed: */
  .select-hide {
    display: none;
  }

  .select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .gray-effect-rows {
    background-color: #f1f1f1 !important;
  }
</style>
