import firebase from 'firebase/app'
import 'firebase/auth'

//Configuration firebase :
const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  application: process.env.VUE_APP_NAME
}

firebase.initializeApp(config)

const provider = new firebase.auth.SAMLAuthProvider('saml.pass')

const signInWithSAML = () => {
  process.env.NODE_ENV === 'localhost' ? firebase.auth().signInWithPopup(provider) : firebase.auth().signInWithRedirect(provider)
}

firebase
  .auth()
  .getRedirectResult()
  .then(async (result) => {
    if (result.user) {
      try {
        const token = await result.user.getIdToken(true)
        const idTokenResult = await result.user.getIdTokenResult(true)
        const user = await authorize(token, config.application, process.env.NODE_ENV === 'development')
        import('./createApp').then((createApp) => {
          createApp.default({ firebaseUser: result.user, user, roles: idTokenResult.claims.roles })
        })
      } catch (error) {
        console.error('Error during user processing after SAML sign-in:', error)
        window.location.href = '/forbidden.html'
      }
    }
  })
  .catch((error) => {
    console.error('Error during SAML sign-in:', error)
  })

// Démarrer l'application si l'utilisateur est déjà authentifié
firebase.auth().onAuthStateChanged(async (firebaseUser) => {
  if (firebaseUser) {
    try {
      const token = await firebaseUser.getIdToken(true)
      const idTokenResult = await firebaseUser.getIdTokenResult(true)
      const user = await authorize(token, config.application, process.env.NODE_ENV === 'development')
      import('./createApp').then((createApp) => {
        createApp.default({ firebaseUser, user, roles: idTokenResult.claims.roles })
      })
    } catch (error) {
      console.error('Error during user processing after auth state change:', error)
      window.location.href = '/forbidden.html'
    }
  } else {
    signInWithSAML()
  }
})

export const authorize = async (idToken, application) => {
  if (!idToken || !application) throw 'IdToken or Application must be specified'

  let headers = new Headers({
    Authorization: `Bearer ${idToken}`,
    application
  })

  const response = await fetch(process.env.VUE_APP_AUTHORIZE_ENDPOINT, {
    headers,
    method: 'GET',
    cache: 'default',
    mode: 'cors'
  })
  if (!response.ok) {
    return response.json().then((message) => {
      throw message
    })
  }
  return response.json()
}

export const logout = async () => {
  try {
    await firebase.auth().signOut()
    window.location.href = process.env.VUE_APP_LOG_OUT_LOCATION
  } catch (error) {
    console.error('Error signing out:', error)
  }
}
