import firebase from 'firebase/app'
import 'firebase/firestore'
import { DateTime } from 'luxon'
import { defaultStartDate, defaultEndDate } from '@/utils/CONF'
import { closestGreater } from '@/utils/utils'
import Vue from 'vue'

const db = firebase.firestore()
const collectionDataAnalytique = db.collection('REFERENTIAL')
const validationInventoryCollection = db.collection('VALIDATION_INVENTORY')
const today = DateTime.local()
const currentDay = new Date().getDate()

const state = {
  families: [],
  habilitations: {},
  closuresDates: [],
  inventoriesDates: [],
  inventories: [],
  formats: [],
  validationInventories: [],
  historyInventories: [],
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  validIndicators: {},
  format: [],
  alertThreshold: 1000000
}

const getters = {
  currentClosureDate(state) {
    // if (today.month === 1 && currentDay >= new Date(state.closuresDates[state.closuresDates.length - 2].closureStartDate.seconds * 1000).getDate()
    //   && currentDay <= new Date(state.closuresDates[state.closuresDates.length - 2].DataAnalytiqueclosureEndDate.seconds * 1000).getDate()) {
    //   return state.closuresDates[state.closuresDates.length - 2]
    // }

    // if (today.month === 1 && currentDay >= new Date(state.closuresDates[state.closuresDates.length - 1].closureStartDate.seconds * 1000).getDate()
    //   && currentDay <= new Date(state.closuresDates[state.closuresDates.length - 1].DataAnalytiqueclosureEndDate.seconds * 1000).getDate()) {
    //   return state.closuresDates[state.closuresDates.length - 1]
    // }

    //Liste des dates de fermeture du fichier de configuration
    const allClosureDateInSecond = state.closuresDates.map((d) => d.DataAnalytiqueclosureEndDate.seconds)
    const closestClosureDate = closestGreater(allClosureDateInSecond, parseInt(new Date().setHours(0, 0, 0) / 1000), 0)
    const closureDate = state.closuresDates.find((date) => date.DataAnalytiqueclosureEndDate.seconds === closestClosureDate)
    return closureDate
  },
  lastClosureDate(state) {
    const allClosureDateInSecond = state.closuresDates.map((d) => d.DataAnalytiqueclosureEndDate.seconds)
    const currentClosestClosureDate = closestGreater(allClosureDateInSecond, parseInt(new Date().setHours(0, 0, 0) / 1000), -1)
    const lastClosureDate = state.closuresDates.find((date) => date.DataAnalytiqueclosureEndDate.seconds === currentClosestClosureDate)
    return lastClosureDate
  },
  formattedFamilies(state) {
    const formats = state.habilitations.formatType.length === 0 ? state.formats : state.habilitations.formatType

    let formattedFamilies = {}

    formats.sort().forEach((type) => {
      formattedFamilies[type.toUpperCase()] = state.families
        .filter((family) => family.formatType.toUpperCase() === type.toUpperCase())
        .sort((a, b) => a.code.localeCompare(b.code))
    })

    return formattedFamilies
  },
  inventoriesDates(state) {
    return state.inventoriesDates
  },
  validationInventories(state) {
    return state.validationInventories
  },
  historyInventories(state) {
    return state.historyInventories
  },
  getHabilitations(state) {
    return state.habilitations
  }
}

const mutations = {
  SET_FAMILIES(state, families) {
    state.formats = [...new Set(families.map((family) => family.formatType.normalize()))]
    state.families = families
  },
  SET_VALID_INDICATORS(state, params) {
    let formattedValidIndicators = {}

    state.formats.forEach((format) => {
      const UCSites = [...new Set(params.ucsite.filter((ucsite) => ucsite.formatType?.toUpperCase().includes(format.toUpperCase().split(','))))]
      const CRs = [...new Set(params.nomenclature.filter((nomenclature) => nomenclature.formatType.toUpperCase() === format.toUpperCase()))]

      formattedValidIndicators[format.normalize().toUpperCase()] = {
        UCSites,
        CRs
      }
    })

    state.validIndicators = formattedValidIndicators
  },

  SET_HABILITATIONS(state, data) {
    let { habilitations, sites } = data
    let filterSites = []

    if (habilitations.site) {
      filterSites = habilitations.site.split(',')
    } else {
      sites.map((elSite) => {
        if (habilitations.formatType) {
          habilitations.formatType.split(',').map((itemHabilitation) => {
            const elSiteFormat = elSite.formatType.toUpperCase().split(',')
            if (elSiteFormat.includes(itemHabilitation.toUpperCase())) {
              filterSites.push(elSite.SITE_ORACLE)
            }
          })
        }
      })
    }
    habilitations.site = filterSites //habilitations.site ? habilitations.site.split(',').filter(Boolean) : habilitations.site = []
    habilitations.formatType = habilitations.formatType ? habilitations.formatType.split(',').filter(Boolean) : []
    state.habilitations = habilitations
  },

  SET_CLOSURES_DATES(state, closuresDates) {
    state.closuresDates = closuresDates.sort((a, b) => parseInt(a.month) - parseInt(b.month))
  },

  SET_PERIOD(state, { startDate, endDate }) {
    state.startDate = startDate
    state.endDate = endDate
  },

  SET_INVENTORIES(state, inventories) {
    state.inventories = inventories
  },

  SET_FORMAT(state, format) {
    state.format = format
  },

  SET_VALIDATION_INVENTORIES(state, validationInventories) {
    state.validationInventories = validationInventories
  },

  SET_HISTORY_INVENTORIES(state, historyInventories) {
    state.historyInventories = historyInventories
  },

  SET_INVENTORIES_DATES(state, inventoriesDates) {
    state.inventoriesDates = inventoriesDates
  }
}

const actions = {
  async fetchParams({ commit }, matricule) {
    try {
      const accountingClosuresDoc = await collectionDataAnalytique.doc('ACCOUNTING_CLOSURES').get()
      const familiesDoc = await collectionDataAnalytique.doc('FAMILIES').get()
      const formatDoc = await collectionDataAnalytique.doc('FORMAT').get()
      const habilitationsDoc = await collectionDataAnalytique.doc('HABILITATIONS').get()
      const lastInventoriesDoc = await collectionDataAnalytique.doc('LAST_INVENTORIES').get()
      const nomenclatureDoc = await collectionDataAnalytique.doc('NOMENCLATURE').get()
      const ucsiteDoc = await collectionDataAnalytique.doc('UCSITE').get()

      commit('SET_CLOSURES_DATES', accountingClosuresDoc.data().accountingClosures)
      commit('SET_FAMILIES', familiesDoc.data().families)
      commit('SET_HABILITATIONS', {
        habilitations: habilitationsDoc.data().habilitations.find((h) => h.matricule === matricule),
        sites: ucsiteDoc.data().ucsite
      })
      commit('SET_INVENTORIES', lastInventoriesDoc.data().lastInventories)
      commit('SET_INVENTORIES_DATES', lastInventoriesDoc.data().lastInventories)
      commit('SET_VALID_INDICATORS', {
        nomenclature: nomenclatureDoc.data().nomenclature,
        ucsite: ucsiteDoc.data().ucsite
      })
      commit('SET_FORMAT', formatDoc.data().format)
    } catch (error) {
      console.error(error)
    }
  },

  async fetchValidationInventories({ commit }, params) {
    const result = await new Promise((resolve) => {
      try {
        let query = validationInventoryCollection.where('site', '==', params.site)
        query = query.where('inventoryDay', '==', params.inventoryDay)
        query
          .get()
          .then((collection) => {
            collection = collection.docs.map((result) => ({ ...result.data() }))
            commit('SET_VALIDATION_INVENTORIES', collection)
            resolve(collection.docs)
          })
          .catch((error) => console.error(error))
      } catch (error) {
        console.error(error)
      }
    })
    return result
  },

  async fetchValidationInventoriesForMultipleSite({ commit }, params) {
    try {
      const promisesList = []
      for (let i = 0; i < params.site.length; i += 10) {
        let sites = params.site.slice(i, i + 10)
        let query = validationInventoryCollection.where('site', 'in', sites)
        query = query.where('inventoryDay', '==', params.inventoryDay)
        promisesList.push(query.get())
      }
      const res = await Promise.all(promisesList)
      const collection = res.map((res) => res.docs.map((result) => ({ ...result.data() })))
      commit('SET_VALIDATION_INVENTORIES', collection)
      return collection
    } catch (error) {
      console.error(error)
    }
  },

  async fetchHistoryInventories({ commit }, params) {
    const result = await new Promise((resolve) => {
      try {
        let query = validationInventoryCollection.where('site', '==', params.site)
        query = query.where('inventoryDay', '==', params.inventoryDay)
        //query.orderBy('validationTimestamp','DESC')

        query
          .get()
          .then((collection) => {
            collection = collection.docs.map((result) => ({ ...result.data() }))
            commit('SET_HISTORY_INVENTORIES', collection)
            resolve(collection.docs)
          })
          .catch((error) => console.error(error))
      } catch (error) {
        console.error(error)
      }
    })
    return result
  },

  async saveValidationInventories({ commit }, allValidations) {
    try {
      let updatedValidations = []
      const batch = db.batch()
      await new Promise((resolve) => {
        resolve(
          allValidations.data.forEach((doc) => {
            if (allValidations.forAllSite) {
              allValidations.siteSelected.forEach((site) => {
                let newDoc = {
                  validationTimestamp: Date.now(),
                  user: allValidations.user,
                  inventoryDay: allValidations.selectedInventory,
                  isInventored: doc.Inventaire,
                  isReplaceInventory: doc.contre,
                  market: doc.Rayon,
                  site: site
                }
                batch.set(validationInventoryCollection.doc(), newDoc)
                updatedValidations.push(newDoc)
              })
            } else {
              let newDoc = {
                validationTimestamp: Date.now(),
                user: allValidations.user,
                inventoryDay: allValidations.selectedInventory,
                isInventored: doc.Inventaire,
                isReplaceInventory: doc.contre,
                market: doc.Rayon,
                site: allValidations.siteSelected
              }
              batch.set(validationInventoryCollection.doc(), newDoc)
              updatedValidations.push(newDoc)
            }
          })
        )
      })

      await batch.commit()
      Vue.toasted.info(`La saisie a bien été enregistrée`)
      commit('SET_VALIDATION_INVENTORIES', updatedValidations)
      return allValidations
    } catch (error) {
      console.error(error)
      Vue.toasted.error(`Erreur lors de la sauvegarde des données`)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
