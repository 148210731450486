<template>
  <div v-if="!modeGrid" class="entry">
    <Spinner :is-loading="loading" :message="loadingMessage" />
    <h1>{{ $t('entry.title') }}</h1>

    <div v-if="!disabledEntry" class="buttons buttons-align-right">
      <div class="extourne">
        <input id="checkbox" v-model="extourne" type="checkbox" style="pointer-events: none" />
        <!--<input type="checkbox" id="checkbox" v-model="extourne" :disabled="selectedFamily.extourne === false">-->
        <label for="extourne">Réaliser une extourne</label>
      </div>
      <v-button class="flat" @click="selectFamily()">
        <v-svg>cancel</v-svg>
        {{ $t('cancel') }}
      </v-button>
      <v-button :disabled="!allIsValid" @click="saveEntries()">
        <v-svg>save</v-svg>
        {{ $t('save') }}
      </v-button>
    </div>

    <div v-else class="buttons buttons-align-right">
      <div style="padding: 12px">
        <span style="margin-bottom: 30px; font-weight: bold">Formulaire &nbsp;</span>
        <label class="switch">
          <input v-model="modeGrid" type="checkbox" />
          <span class="slider round" />
        </label>
        <span style="margin-bottom: 30px; font-weight: bold">&nbsp; Tableau &nbsp;</span>
      </div>
      <v-button class="flat" style="margin: 0 5px; height: 46px" @click="goBackToEntries(selectedFormat)">
        <v-svg>cancel</v-svg>
        {{ $t('entry.goBackToEntry') }}
      </v-button>
      <div>
        <input id="file-upload" ref="file" type="file" accept=".csv" class="input-file" :disabled="lockedEntry" @change="checkIfAlreadyUploaded" />
      </div>
      <div style="text-align: center">
        <v-button :disabled="lockedEntry" @click="onPickFile">
          <v-svg>import</v-svg>
          <span>{{ $t('button.upload') }}</span>
        </v-button>
        <a class="download-template" @click="downloadCSVTemplate">{{ $t('button.downloadTemplate') }}</a>
      </div>
    </div>

    <div class="content" :class="{ 'family-selected': !disabledEntry }">
      <section class="family-choice">
        <template v-if="loading">
          <loading />
        </template>
        <template v-else>
          <div class="select-family">
            <div>
              <h2>{{ selectedFormat }}</h2>
            </div>
          </div>
          <div v-if="lockedEntry" class="locked-entry">
            {{ $t('entry.messageLockedEntry') }}
          </div>
          <div v-if="!lockedEntry" class="families">
            <button v-for="(family, index) in familiesFiltered" :key="index" class="family" @click="selectFamily(family)">
              <div class="code">{{ family.code }}</div>
              <div class="description">{{ family.description }}</div>
            </button>
          </div>
        </template>
      </section>
      <section class="entries" :class="{ disabled: disabledEntry }">
        <h2>Saisie des Démarques</h2>
        <div class="details">
          <div>
            <span class="label">{{ $t('date') }}:</span> {{ date.toFormat('dd/MM/yyyy') }}
          </div>
          <div>
            <span class="label">{{ $t('user') }}:</span> {{ userCode }}
          </div>
          <div>
            <span class="label">{{ $t('family') }}:</span>
            {{
              !disabledEntry
                ? `${selectedFamily.code} : ${selectedFamily.description}`
                : `Vous n'avez pas encore
            selectionné de famille`
            }}
          </div>
          <div>
            <span class="label">{{ $t('entry.totalAmount') }} </span> {{ amountTotal }}
          </div>
          <div v-if="!allIsValid" class="error-message">
            {{ $t('entry.error') }}
            <span v-if="isAmountZero && amountTotal > 0">
              <br />
              {{ $t('entry.errorAmount') }}
            </span>
          </div>
        </div>
        <div v-if="entries.length" class="grid">
          <div />
          <div v-for="name in Object.keys(entries[0])" :key="`header#${name}`" class="header cell">{{ $t(name) }}</div>
          <div />
          <template v-for="(vEntry, i) in $v.entries.$each.$iter">
            <div :key="`delete#${i}`" class="cell">
              <v-button tabindex="-1" class="flat" @click="removeLine(i)"><v-svg>cancel</v-svg></v-button>
            </div>
            <div :key="`uc#${i}`" class="cell">
              <input
                v-model="vEntry.$model.accountingUnit"
                v-tooltip.bottom-start="vEntry.accountingUnit.$invalid ? 'UC non valide' : ''"
                :list="`input-uc#${i}`"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.accountingUnit.$invalid }"
                placeholder="ex: FRH002A"
                type="text"
                @click="activeLine = i"
              />
              <datalist :id="`input-uc#${i}`">
                <option v-for="uc in validAccountingUnits(vEntry.$model.siteId)" :key="`option-uc#${i}:${uc}`" :value="uc">
                  {{ uc }}
                </option>
              </datalist>
            </div>
            <div :key="`site#${i}`" class="cell">
              <input
                v-model="vEntry.$model.siteId"
                v-tooltip.bottom-start="vEntry.siteId.$invalid ? 'Site non valide' : ''"
                :list="`sites`"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.siteId.$invalid }"
                placeholder="ex: 0002"
                type="text"
                @focus="fillDataListIfEmpty(`sites`)"
                @input="completeDataList($event, `sites`)"
                @click="activeLine = i">
              <datalist :id="`sites`">
              </datalist>
            </div>

            <div :key="`cr#${i}`" class="cell">
              <input
                v-model="vEntry.$model.resultCenterId"
                v-tooltip.bottom-start="vEntry.resultCenterId.$invalid ? 'CR non valide' : ''"
                :list="`crs`"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.resultCenterId.$invalid }"
                placeholder="ex:0002-INVENTAIRE"
                type="number"
                @focus="fillDataListIfEmpty('crs')"
                @input="completeDataList($event, 'crs')"
                @click="activeLine = i"
              />
              <datalist :id="`crs`">
                <!-- <option
                  v-for="cr in validResultCenterIds()"
                  :key="`option-cr#${i}:${cr}`"
                  :value="cr">
                  {{ cr }}
                </option> -->
              </datalist>
            </div>

            <div :key="`crlabel#${i}`" class="cell">
              <span>
                {{ getResultCenterLabel(vEntry.$model.resultCenterId) }}
              </span>
            </div>

            <div :key="`amount#${i}`" class="cell">
              <input
                v-model="vEntry.$model.amount"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.amount.$invalid }"
                placeholder="ex: -222"
                type="number"
                step="0.001"
                @blur="showModalIfAboveThreshold(vEntry.$model.amount)"
                @click="activeLine = i"
              />
            </div>

            <div :key="`label#${i}`" class="cell">
              <input
                v-model="vEntry.$model.label"
                maxlength="40"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.label.$invalid }"
                placeholder="ex: Écart entre Pilot et Hyperion"
                type="text"
                @click="activeLine = i"
              />
            </div>

            <div v-if="selectedFamily" :key="`buttons#${i}`" class="cell">
              <v-button class="flat" @click="newLine"><v-svg>plus</v-svg></v-button>
            </div>
          </template>
        </div>
      </section>
    </div>

    <v-modal v-if="showModal">
      <h3 slot="header">{{ $t('entry.modal.warning') }} !</h3>
      <span slot="body">{{ warningMessage }} </span>
      <v-button slot="footer" class="small" @click="showModal = false">{{ $t('entry.modal.continue') }}</v-button>
    </v-modal>

    <v-modal v-if="showConfirmModal">
      <h3 slot="header">{{ $t('entry.modal.warning') }} !</h3>
      <span slot="body">{{ $t('entry.modal.fileMessage') }} </span>
      <v-button slot="footer" class="small" @click="upload(true)">{{ $t('entry.modal.continue') }}</v-button>
      <v-button slot="footer" class="small" @click="closeConfirmModal">{{ $t('entry.modal.cancel') }}</v-button>
    </v-modal>

    <v-modal v-if="showErrorModal">
      <h3 slot="header">Erreur lors de l'enregistrement du fichier</h3>
      <div slot="body" style="max-height: 400px; overflow-x: scroll">
        <div v-html="errorMessages.join('')" />
      </div>
      <v-button slot="footer" class="small" @click="showErrorModal = false">{{ $t('close') }}</v-button>
    </v-modal>
  </div>
  <div v-else class="entry">
    <Spinner :is-loading="loading" :message="loadingMessage" />
    <div class="buttons buttons-align-right">
      <div style="padding: 12px">
        <span style="margin-bottom: 30px; font-weight: bold">Formulaire &nbsp;</span>
        <label class="switch">
          <input v-model="modeGrid" type="checkbox" />
          <span class="slider round" />
        </label>
        <span style="margin-bottom: 30px; font-weight: bold">&nbsp; Tableau &nbsp;</span>
      </div>
      <v-button class="flat" style="margin: 0 5px; height: 46px" @click="goBackToEntries(selectedFormat)">
        <v-svg>cancel</v-svg>
        {{ $t('entry.goBackToEntry') }}
      </v-button>
      <div>
        <input id="file-upload" ref="file" type="file" accept=".csv" class="input-file" :disabled="lockedEntry" @change="checkIfAlreadyUploaded" />
      </div>
      <div style="text-align: center">
        <v-button :disabled="lockedEntry" @click="onPickFile">
          <v-svg>import</v-svg>
          <span>{{ $t('button.upload') }}</span>
        </v-button>
        <a class="download-template" @click="downloadCSVTemplate">{{ $t('button.downloadTemplate') }}</a>
      </div>
    </div>
    <h1>{{ $t('entry.EnteringInventories') }}</h1>
    <div class="details">
      <div>
        <span class="label">{{ $t('date') }}:</span> {{ date.toFormat('dd/MM/yyyy') }}
      </div>
      <div>
        <span class="label">{{ $t('user') }}:</span> {{ userCode }}
      </div>
      <div>
        <span class="label">{{ $t('siteId') }}: </span>
        <select v-model="siteSelected" style="width: 100px" @change="onSiteChange($event)">
          <option v-for="option in options" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div>
        <span class="label">{{ $t('inventoryDate') }}: </span>
        <select @change="onInventoryDateChange($event)">
          <option value="" :selected="selectedInventory === ''" disabled>Sélectionnez un jour d'inventaire</option>
          <option v-for="(inventory, index) in selectedSiteInventoriesDates" :key="index" :value="inventory.date">
            {{ inventory.label }}
          </option>
        </select>

        <button style="float: right; margin-right: 7px" :disabled="lockedEntry || !selectedInventory" @click="onShowModalInventoryValidation">
          <v-svg>doc</v-svg>&nbsp;
          <span>Validation des inventaires</span>
        </button>
        <button style="float: right; margin-right: 7px" :disabled="lockedEntry || !selectedInventory" @click="onShowModalInventoryHistory">
          <v-svg>doc</v-svg>&nbsp;
          <span>Historique des inventaires</span>
        </button>
      </div>
    </div>
    <div style="height: 80%">
      <ag-grid-vue
        style="height: 100%"
        class="ag-theme-alpine"
        :column-defs="columnDefs"
        :row-data="rowData"
        :get-row-class="getRowClass"
        :column-types="columnTypes"
        :suppress-scroll-on-new-data="true"
        @grid-ready="onGridReady"
        @cell-value-changed="onCellValueChanged"
      />
    </div>
    <v-modal v-if="showModal" class="toto">
      <h3 slot="header">{{ $t('restitution.title') }}</h3>
      <div slot="body" style="overflow-x: scroll">
        <GridEntries :entries="slicedEntries()" :headers="headers" />
      </div>
      <v-button slot="footer" class="small" @click="showModal = false">{{ $t('close') }}</v-button>
    </v-modal>
    <v-modal v-if="showConfirmModal">
      <h3 slot="header">{{ $t('entry.modal.warning') }} !</h3>
      <span slot="body">{{ $t('entry.modal.fileMessage') }} </span>
      <v-button slot="footer" class="small" @click="closeConfirmModal">{{ $t('entry.modal.cancel') }}</v-button>
    </v-modal>
    <v-modal v-if="showModalInventoryValidation">
      <h3 slot="header">Validation des inventaires</h3>
      <div slot="body">
        <div class="details-model">
          <div>
            <span class="label">{{ $t('siteId') }}:</span> {{ siteSelected }}
          </div>
          <div>
            <span class="label">{{ $t('inventoryDate') }}:</span> {{ selectedInventory }}
          </div>
        </div>
        <ag-grid-vue
          class="ag-theme-alpine"
          style="width: 1050px; height: 400px"
          :column-defs="columnValidationDefs"
          :row-data="validationInventoryData"
          :row-selection="null"
          :get-row-class="getRowClass"
          :suppress-scroll-on-new-data="true"
          @cell-clicked="onSelectionChanged"
          @grid-ready="onGridValidationReady"
        />
      </div>
      <v-button slot="footer" class="small green" @click="saveValidations()"> Valider </v-button>
      <v-button slot="footer" class="small mr-1" @click="closeModel"> Fermer </v-button>
    </v-modal>

    <v-modal v-if="showModalInventoryHistory">
      <h3 slot="header">Historique des validations inventaires</h3>
      <div slot="body">
        <div class="details-model">
          <div>
            <span class="label">{{ $t('siteId') }}:</span> {{ siteSelected }}
          </div>
          <div>
            <span class="label">{{ $t('inventoryDate') }}:</span> {{ selectedInventory }}
          </div>
        </div>
        <ag-grid-vue
          class="ag-theme-alpine"
          style="width: 1050px; height: 400px"
          :column-defs="columnHistoryDefs"
          :row-data="historyInventoryData"
          :row-selection="null"
          overlay-no-rows-template="Aucune donnée disponible"
          :get-row-class="getRowClass"
          :suppress-scroll-on-new-data="true"
          @grid-ready="onGridHistoryReady"
        />
      </div>
      <v-button slot="footer" class="small mr-1" @click="closeModel"> Fermer </v-button>
    </v-modal>
    <v-modal v-if="showErrorModal">
      <h3 slot="header">Erreur lors de l'enregistrement du fichier</h3>
      <div slot="body" style="max-height: 400px; overflow-x: scroll">
        <div v-html="errorMessages.join('')" />
      </div>
      <v-button slot="footer" class="small" @click="showErrorModal = false">{{ $t('close') }}</v-button>
    </v-modal>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapActions, mapState, mapGetters } from 'vuex'
  import { required } from 'vuelidate/lib/validators'
  import { blockedEntry, formatAmount, pad } from '../utils/utils'
  import { DateTime } from 'luxon'
  import { importCsv } from '@/utils/csv'
  import Spinner from '@/components/base/Spinner'
  import 'ag-grid-community/dist/styles/ag-grid.css'
  import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
  import { AgGridVue } from 'ag-grid-vue'
  import NumericEditor from '../components/grid/numericEditor.vue'
  import CellRenderer from '../components/grid/cellRenderer.vue'
  import GridEntries from '@/components/GridEntries'
  import MoodRenderer from '../components/grid/moodRenderer.vue'
  import CheckBoxRenderVue from '../components/grid/checkBoxRender.vue'
  import CustomHeader from '../components/grid/customHeader.vue'
  import i18n from '@/i18n'
  import moment from 'moment'
  import extourne from '@/mixins/extourneSuper'

  const emptyLine = { accountingUnit: '', siteId: '', resultCenterId: '', resultCenterLabel: '', amount: '', label: '' }
  window.isCellEditable = function isCellEditable(params) {
    return params
  }
  export default {
    name: 'EntryDemarqueInventaire',
    components: {
      Spinner,
      'ag-grid-vue': AgGridVue,
      numericEditor: NumericEditor,
      cellRenderer: CellRenderer,
      moodRenderer: MoodRenderer,
      CheckBoxRenderVue: CheckBoxRenderVue,
      customHeader: CustomHeader,
      GridEntries
    },
    mixins: [extourne],
    data() {
      return {
        allData: [],
        modeGrid: true,
        columnDefs: [],
        gridApi: null,
        columnTypes: null,
        gridValidationApi: null,
        gridHistoryApi: null,
        columnApi: null,
        familiesFiltered: [],
        defaultColDef: {
          editable: true
        },
        siteSelected: '',
        rowData: null,
        getRowClass: null,
        loading: false,
        loadingMessage: '',
        selectedFamily: undefined,
        date: DateTime.local(),
        entries: [],
        activeLine: 0,
        userCode: this.$userInfos.uid,
        file: null,
        showModal: false,
        showConfirmModal: false,
        showErrorModal: false,
        selectedFormat: '',
        defaultFormat: [],
        inventoriesList: [],
        haveToDelete: false,
        today: new Date(),
        warningMessage: '',
        extourne: false,
        errorMessages: [],
        options: [],
        start: 0,
        rowCount: '',
        dataByCell: [],
        headers: ['user', 'date', 'inventoryDate', 'format', 'accountingUnit', 'siteId', 'resultCenterId', 'family', 'amount', 'label', 'entryType'],
        showModalInventoryValidation: false,
        showModalInventoryHistory: false,
        columnValidationDefs: [
          { headerName: 'Rayon', field: 'CR' },
          { headerName: 'Inventorié METI', field: 'CR1', cellRenderer: 'moodRenderer' },
          { headerName: 'A déclencher', field: 'Inventaire', cellRenderer: 'CheckBoxRenderVue' },
          { headerName: 'Inventorié DA', field: 'Inventorie', cellRenderer: 'moodRenderer' },
          { headerName: 'Contre Inventaire', field: 'contre', cellRenderer: 'CheckBoxRenderVue' }
        ],
        columnHistoryDefs: [
          { headerName: 'Rayon', field: 'market' },
          { headerName: 'Déclencher', field: 'isInventored', cellRenderer: 'moodRenderer' },
          { headerName: 'Contre Inventaire', field: 'contre', cellRenderer: 'moodRenderer' },
          { headerName: 'Utilisateur', field: 'user' },
          { headerName: 'Date de validation', field: 'validationTimestamp' }
        ],
        validationInventoryData: [],
        historyInventoryData: [],
        selectedInventory: '',
        selectedSiteInventoriesDates: []
      }
    },
    computed: {
      ...mapGetters('configuration', ['currentClosureDate', 'formattedFamilies', 'inventoriesDates', 'validationInventories', 'historyInventories']),
      ...mapGetters('entries', ['allEntries']),
      ...mapState('configuration', ['habilitations', 'families', 'validIndicators', 'formats', 'alertThreshold', 'inventories', 'closuresDates']),
      lockedEntry() {
        return this.isUserCDG ? false : blockedEntry(this.currentClosureDate, this.selectedFormat)
      },
      disabledEntry() {
        return !this.selectedFamily
      },
      fileName() {
        return this.file && this.file.name.split('.')[0]
      },
      imputationDate() {
        return this.currentClosureDate.year.toString() + this.currentClosureDate.month.toString().padStart(2, '0')
      },
      entriesForStore() {
        return this.entries.reduce((acc, entry) => {
          acc.push({
            accountingUnit: entry.accountingUnit,
            format: this.selectedFormat,
            site: entry.siteId,
            resultCenter: parseInt(entry.resultCenterId),
            label: entry.label,
            amount: entry.amount ? parseFloat(entry.amount) : 0,
            inputDate: this.today,
            imputationDate: this.selectedInventory,
            family: this.selectedFamily.code,
            familyAccount: this.selectedFamily.account,
            familyRubric: this.selectedFamily.rubrique,
            user: this.userCode,
            entryType: this.$t('manual')
          })
          return acc
        }, [])
      },
      entriesExtourne() {
        return ![7, 14].includes(this.currentClosureDate.month) && this.extourne
          ? this.entriesForStore.map((a) => {
              return {
                ...a,
                amount: -a.amount,
                entryType: this.$t('entry.extourne'),
                imputationDate: this.nextImputationDate
              }
            })
          : []
      },
      amountTotal() {
        return this.entries.map((e) => (e.amount ? parseInt(e.amount) : 0)).reduce((a, b) => a + b, 0)
      },
      isAmountZero() {
        return this.selectedFamily && this.selectedFamily.isAmountZero
      },
      allIsValid() {
        if (this.isAmountZero) {
          return !this.$v.entries.$invalid && this.amountTotal === 0
        } else {
          return !this.$v.entries.$invalid
        }
      }
    },
    watch: {
      selectedFamily(val) {
        if (val && this.validAccountingUnits('').length === 1) {
          this.$v.entries.$each.$iter[0].$model.accountingUnit = this.validAccountingUnits('')[0]
        }

        if (val && this.validSiteIds(this.$v.entries.$each.$iter[0].$model.accountingUnit).length === 1) {
          this.$v.entries.$each.$iter[0].$model.siteId = this.validSiteIds(this.$v.entries.$each.$iter[0].$model.accountingUnit)[0]
        }

        this.extourne = val && val.extourne === false ? false : true
      }
    },
    mounted() {
      this.defaultFormat = this.habilitations.formatType
      this.selectedFormat = this.$route.query.format
      this.options = this.validSiteIds()
      this.siteSelected = this.options[0]
      ;(this.columnDefs = this.generateColumns(this.familiesFiltered)), this.getInventoryDateByParam(this.siteSelected)
    },
    async created() {
      this.selectedFormat = this.$route.query.format
      this.familiesFiltered = this.filteredFamiliesByType(this.formattedFamilies[this.selectedFormat], '0002-INVENTAIRE')
      this.getRowClass = (params) => {
        if (params.node.rowIndex % 2 != 0) {
          return 'gray-effect-rows'
        }
      }
      this.siteSelected = this.options[0]
      this.columnTypes = {
        editableColumn: {
          editable: () => {
            return isCellEditable(this.selectedInventory != '')
          }
        }
      }
    },
    methods: {
      ...mapActions('entries', ['insertEntries', 'deleteAndInsertEntries', 'fetchEntriesByType', 'fetchEntriesByParams', 'fetchEntries']),
      ...mapActions('configuration', ['fetchValidationInventories', 'fetchHistoryInventories', 'saveValidationInventories']),
      async onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        const updateData = (data) => params.api.setRowData(data)
        this.allData = await this.fetchEntriesByParams({
          site: this.siteSelected,
          format: this.selectedFormat.split(),
          imputationDate: this.selectedInventory
        })
        updateData(this.generateRows(this.siteSelected, '0002-INVENTAIRE'))
        /*fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
           .then((resp) => resp.json())
           .then((data) => updateData(data));*/
      },

      async onGridValidationReady(params) {
        await params.api.setRowData(this.validationInventoryData)
        this.gridValidationApi = params.api
      },
      async onGridHistoryReady(params) {
        await params.api.setRowData(this.historyInventoryData)
        this.gridHistoryApi = params.api
      },

      onSelectionChanged(event) {
        const selectedCell = event
        const selectedCheckbox = selectedCell.event.target.checked
        const cellIndex = selectedCell.rowIndex
        if (selectedCheckbox !== undefined && selectedCell.colDef.field === 'Inventaire') {
          this.validationInventoryData[cellIndex].Inventaire = selectedCheckbox
        } else if (selectedCheckbox !== undefined && selectedCell.colDef.field === 'contre') {
          if (!this.validationInventoryData[cellIndex].Inventaire) {
            selectedCell.event.target.checked = false
            Vue.toasted.error(`Vous ne pouvez pas cocher cette case`)
          } else {
            this.validationInventoryData[cellIndex].contre = selectedCheckbox
          }
        }
      },

      generateRows(selectedSiteId, familyAccount) {
        let dataRows = []
        this.validationInventoryData = []
        this.historyInventoryData = []

        const marketList = this.inventoriesList.map((inventory) => inventory.market)

        for (const CR of this.validResultCenterIds(selectedSiteId)) {
          if ([10, 11, 12, 13, 21, 22, 43, 50, 51, 60].includes(parseInt(CR))) {
            // Conserver uniquement les rayons 10 11 12 13 21 22 43 50 51 60
            let concatRow = {}
            let mappedCR = { CR: CR + '- ' + this.getResultCenterLabel(CR) }
            let isInventorie = false
            for (const family of this.familiesFiltered) {
              let totalAmount = this.getTotalAmount(selectedSiteId, family.code, CR, familyAccount, this.allData)
              let mappedFamily = { [family.code]: totalAmount }
              concatRow = Object.assign(concatRow, mappedFamily)
              if (!isInventorie && totalAmount !== 0) {
                isInventorie = true
              }
            }

            concatRow = { ...concatRow, CR1: !this.selectedInventory ? null : marketList.includes(CR) ? 'Oui' : 'Non' }
            if (this.selectedInventory) {
              //const curentMarketData = this.validationInventories.filter(inv => inv.market === CR )

              let validationData = {
                ...mappedCR,
                Rayon: CR,
                CR1: marketList.includes(CR) ? 'Oui' : 'Non',
                Inventaire: false,
                Inventorie: isInventorie ? 'Oui' : 'Non',
                contre: false
              }
              //let validationData = {...mappedCR, 'Rayon': CR, 'CR1': marketList.includes(CR) ? 'Oui' : 'Non', Inventaire: curentMarketData.length ? curentMarketData[0].isInventored : false, Inventorie: isInventorie ? 'Oui' : 'Non', contre: curentMarketData.length ? curentMarketData[0].isReplaceInventory : false}

              this.validationInventoryData.push(validationData)
            }

            concatRow = Object.assign(mappedCR, concatRow)
            dataRows.push(concatRow)
          }
        }
        if (this.selectedInventory) {
          const currentHistoryMarketData = this.historyInventories.filter((inv) => inv.isInventored)

          if (currentHistoryMarketData.length) {
            for (const inv of currentHistoryMarketData) {
              let historyData = {
                market: inv.market + '- ' + this.getResultCenterLabel(inv.market),
                isInventored: inv.isInventored ? 'Oui' : 'Non',
                contre: inv.isReplaceInventory ? 'Oui' : 'Non',
                user: inv.user,
                validationTimestamp: inv.validationTimestamp
              }
              this.historyInventoryData.push(historyData)
            }
          }
        }
        this.historyInventoryData = _.sortBy(this.historyInventoryData, 'validationTimestamp')

        let tmpConvertDate = []
        this.historyInventoryData.map((el) => {
          tmpConvertDate.push({ ...el, validationTimestamp: moment(el.validationTimestamp).format('DD/MM/YYYY à HH:mm') })
        })
        this.historyInventoryData = tmpConvertDate.reverse()

        return dataRows
      },

      getTotalAmount(selectedSiteId, familyCode, resultCenter, familyAccount, entriesData) {
        let totalAmount = 0
        for (const entry of entriesData) {
          if (
            selectedSiteId === entry.site &&
            familyCode === entry.family &&
            resultCenter === entry.resultCenter.toString() &&
            familyAccount === entry.familyAccount.toString() &&
            this.selectedInventory === entry.imputationDate
          ) {
            totalAmount += entry.amount
          }
        }
        return totalAmount
      },

      getfilteredEntries(selectedSiteId, familyCode, resultCenter, familyAccount, entriesData) {
        let entries = []
        for (const entry of entriesData) {
          if (
            selectedSiteId === entry.site &&
            familyCode === entry.family &&
            resultCenter === entry.resultCenter.toString() &&
            familyAccount === entry.familyAccount.toString() &&
            this.selectedInventory === entry.imputationDate
          ) {
            entries.push(entry)
          }
        }
        return entries
      },

      generateColumns(data) {
        let columnDefinitions = []
        let _this = this
        columnDefinitions.push(
          {
            headerName: 'Rayons',
            pinned: 'left',
            field: 'CR',
            width: 235,
            editable: false,
            cellStyle: { 'font-weight': 'bold' }
          },
          {
            headerName: '',
            pinned: 'left',
            field: 'CR1',
            cellRenderer: 'moodRenderer',
            editable: false,
            width: 50,
            cellStyle: { 'font-weight': 'bold' }
          }
        ),
          data.map((object) => {
            let mappedColumn = {
              headerComponent: 'customHeader',
              headerComponentParams: {
                label: object.description.toUpperCase(),
                secondLabel: object.code
              },
              //headerName: object.description.toUpperCase(),
              field: object.code,
              width: 235, //12 * object.description.length,
              cellStyle: { 'border-right': '1px solid lightgray' },
              cellEditor: 'numericEditor',
              cellRenderer: 'cellRenderer',
              cellRendererParams: {
                siteId: this.siteSelected,
                familyAccount: '0002-INVENTAIRE',
                clicked: function (params) {
                  const CR = params.data.CR.substr(0, params.data.CR.indexOf('-'))
                  _this.dataByCell = _this.getfilteredEntries(_this.siteSelected, params.colDef.field, CR, params.familyAccount, _this.allData)
                  _this.showModal = true
                }
              },
              type: 'editableColumn'
            }
            columnDefinitions.push(mappedColumn)
          })
        //Remove duplicate columns
        columnDefinitions = columnDefinitions.filter(
          (column, index, self) => index === self.findIndex((colAtIndex) => colAtIndex.field === column.field)
        )
        return columnDefinitions
      },

      async onSiteChange(event) {
        this.siteSelected = event.target.value
        this.selectedInventory = ''
        this.getInventoryDateByParam(this.siteSelected)
        this.reloadDataGrid(this.selectedInventory)
      },

      async onInventoryDateChange(event) {
        this.loading = true
        this.selectedInventory = this.formatDate(new Date(event.target.value * 1000))
        this.getInventoryDateByParam(this.siteSelected, event.target.value)
        await this.fetchValidationInventories({ site: this.siteSelected, inventoryDay: this.selectedInventory })

        await this.fetchHistoryInventories({ site: this.siteSelected, inventoryDay: this.selectedInventory })
        this.loading = false
        this.reloadDataGrid(this.selectedInventory)
      },

      async reloadDataGrid(selectedInventory = null) {
        this.allData = await this.fetchEntriesByParams({
          site: this.siteSelected,
          format: this.selectedFormat.split(),
          imputationDate: selectedInventory
        })
        this.gridApi.setRowData(this.generateRows(this.siteSelected, '0002-INVENTAIRE'))
        this.gridApi.redrawRows()
      },

      async onCellValueChanged(event) {
        if (event.value) {
          const CR = event.data.CR
          const amount = event.value
          const familyCode = event.colDef.field
          await this.insertEntries(
            this.entryToAdd(CR.substr(0, CR.indexOf('-')), amount, '0002-INVENTAIRE', familyCode).map((entry) => {
              return entry
            })
          ).then(async () => {
            this.allData = await this.fetchEntriesByParams({
              site: this.siteSelected,
              format: this.selectedFormat.split()
            })
          })
        }
        await this.reloadDataGrid()
      },

      async saveValidations(forAllSite = false) {
        this.loading = true
        await this.saveValidationInventories({
          data: this.validationInventoryData,
          selectedInventory: this.selectedInventory,
          siteSelected: forAllSite ? this.options : this.siteSelected,
          user: this.$userInfos.uid,
          forAllSite
        })
        //  await this.gridApi.setRowData(this.generateRows(this.siteSelected, '0002-INVENTAIRE'))
        this.gridValidationApi.setRowData(this.validationInventories)
        await this.fetchHistoryInventories({ site: this.siteSelected, inventoryDay: this.selectedInventory })
        this.loading = false
        this.showModalInventoryValidation = false
        await this.reloadDataGrid(this.selectedInventory)
      },

      entryToAdd(resultCenterId, amount, familyAccount, familyCode) {
        return [
          {
            accountingUnit: 'DEFAULT',
            format: this.selectedFormat,
            site: this.siteSelected,
            resultCenter: parseInt(resultCenterId),
            label: this.getResultCenterLabel(resultCenterId),
            amount: amount ? parseFloat(amount) : 0,
            inputDate: this.today,
            imputationDate: this.selectedInventory,
            family: familyCode,
            familyAccount: familyAccount,
            familyRubric: familyCode,
            user: this.userCode,
            entryType: this.$t('manual_grid')
          }
        ]
      },

      slicedEntries() {
        let searchedEntries = []
        searchedEntries = this.dataByCell.map((entry) => {
          return {
            user: entry.user,
            date: entry.inputDate,
            imputationDate: this.selectedInventory,
            accountingUnit: entry.accountingUnit,
            resultCenterId: entry.resultCenter.toString() + '- ' + this.getResultCenterLabel(entry.resultCenter.toString()),
            siteId: entry.site,
            family: entry.family,
            amount: entry.amount,
            label: entry.label,
            format: entry.format,
            entryType: entry.entryType ? entry.entryType : i18n.t('manual')
          }
        })
        searchedEntries = searchedEntries.filter((entry) => Object.values(entry))
        searchedEntries = [...new Set(searchedEntries.flat())].sort((a, b) => b.date.seconds - a.date.seconds)
        return searchedEntries && searchedEntries.slice(this.start, this.start + searchedEntries.length)
      },

      completeDataList(e, id) {
        const dlOptions =
          id === 'sites'
            ? this.validSiteIds(this.$v.entries.$each.$iter[0].accountingUnit.$model).map((o) => {
                return [`<option value="${o}">${this.getSiteLabel(o)}</option>`, o.toLowerCase()]
              })
            : this.validResultCenterIds(this.$v.entries.$each.$iter[0].siteId.$model).map((o) => {
                return [`<option value="${o}">${this.getResultCenterLabel(o)}</option>`, o.toLowerCase()]
              })
        const fill = (val) => (document.getElementById(id).innerHTML = val)
        if (!e.target.value) {
          fill(dlOptions.reduce((sum, [html]) => sum + html, ''))
        } else if (!(e instanceof InputEvent)) {
          // OR: else if(!e.inputType)
          e.target.blur()
        } else {
          const inputValue = e.target.value.toLowerCase()
          let result = ''
          for (const [html, valuePattern] of dlOptions) {
            if (!valuePattern.indexOf(inputValue)) {
              result += html
            } else if (result) {
              break
            }
          }
          fill(result)
        }
      },

      fillDataListIfEmpty(id) {
        if (!document.getElementById(id).innerHTML) {
          this.completeDataList({ target: {} }, id)
        }
      },

      validAccountingUnits(siteId) {
        return [
          ...new Set(
            this.validIndicators[this.selectedFormat].UCSites.filter((UCSites) => !siteId.length || siteId === UCSites.SITE_ORACLE).map(
              (UCSites) => UCSites.UC_ORACLE
            )
          )
        ].sort()
      },

      validSiteIds(accountingUnit) {
        return [
          ...new Set(
            this.validIndicators[this.selectedFormat].UCSites.filter(
              (UCSites) =>
                (!accountingUnit?.length || accountingUnit === UCSites.UC_ORACLE) &&
                (!this.habilitations.site?.length || this.habilitations.site.includes(UCSites.SITE_ORACLE))
            ).map((UCSites) => UCSites.SITE_ORACLE)
          )
        ].sort()
      },

      getSiteLabel(siteID) {
        return this.validIndicators[this.selectedFormat].UCSites.find((UCSites) => UCSites.SITE_ORACLE === siteID).siteLabel ?? ''
      },

      validResultCenterIds() {
        return [...new Set(this.validIndicators[this.selectedFormat].CRs.map((CRS) => CRS.CR.toString()))]
          .map((uc) => (uc.length === 4 ? '0' + uc : uc))
          .sort()
      },

      getResultCenterLabel(resultCenterID) {
        return resultCenterID && this.validResultCenterIds().includes(resultCenterID)
          ? this.validIndicators[this.selectedFormat].CRs.find((CR) => CR.CR.toString() === resultCenterID).LibelleCR
          : ''
      },

      selectFamily(family) {
        this.selectedFamily = family
        if (!family) this.entries = []
        else this.newLine()
      },

      newLine() {
        const lastLine = this.entries[this.entries.length - 1]
        this.entries.push(
          lastLine
            ? {
                ...emptyLine,
                accountingUnit: lastLine.accountingUnit,
                siteId: lastLine.siteId,
                resultCenterId: lastLine.resultCenterId,
                resultCenterLabel: lastLine.resultCenterLabel,
                label: this.selectedFamily.description
              }
            : { ...emptyLine, label: this.selectedFamily.description }
        )
        this.activeLine = this.entries.length - 1
      },

      removeLine(i) {
        this.entries.splice(i, 1)
        if (!this.entries.length) {
          this.entries = [{ ...emptyLine }]
        }
      },

      saveEntries() {
        this.insertEntries(this.entriesForStore.concat(this.entriesExtourne)).then(() => this.selectFamily())
      },

      showModalIfAboveThreshold(amount) {
        if (Math.abs(amount) >= this.alertThreshold) {
          this.showModal = true
          this.warningMessage =
            this.$t('entry.modal.alertThresholdMessage') +
            new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(Number(this.alertThreshold))
        }
      },
      onPickFile() {
        this.$refs.file.click()
      },
      closeConfirmModal() {
        this.showConfirmModal = false
        this.loading = false
      },
      closeModel() {
        this.showModalInventoryValidation = false
        this.showModalInventoryHistory = false
      },
      async checkIfAlreadyUploaded(e) {
        this.loading = true
        this.loadingMessage = 'Vérification du fichier...'
        this.file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0]
        if (!this.file) return
        else {
          const alreadyUploaded = await this.fetchEntriesByType(this.fileName)
          if (alreadyUploaded) this.showConfirmModal = true
          else this.upload(false)
        }
        e.target.value = ''
      },
      upload(haveToDelete) {
        this.closeConfirmModal()
        this.haveToDelete = haveToDelete
        const reader = new FileReader()
        reader.onload = (r) => {
          const formattedCSV = importCsv(r.target.result)
          if (
            formattedCSV[0].CodeRubrique &&
            formattedCSV[0].Libelle &&
            formattedCSV[0].UC &&
            formattedCSV[0].Site &&
            formattedCSV[0].Rayon &&
            formattedCSV[0].Montant &&
            formattedCSV[0].Format
          ) {
            this.checkFile(formattedCSV, this.fileName)
          } else {
            Vue.toasted.error(`Erreur lors de l'enregistrement du fichier</br> ${this.$t('restitution.error.file')}`)
          }
        }
        reader.readAsText(this.file)
      },
      async checkFile(entries, fileName) {
        this.errorMessages = []
        let allAmountZero = {}
        this.loading = true
        let formatsListAll = this.formats.map((format) => format.toUpperCase())

        entries.forEach((entry, index) => {
          if (this.checkProperties(entry)) {
            let UCSiteOk = true
            let CROk = true
            entry.Montant = formatAmount(entry.Montant)
            const formatOk = formatsListAll.includes(entry.Format.toUpperCase()) && this.selectedFormat.toUpperCase() === entry.Format.toUpperCase()
            const familyOk = this.families.some((f) => f.code === entry.CodeRubrique && entry.Format.toUpperCase() === f.formatType.toUpperCase())
            if (formatOk) {
              UCSiteOk = this.validIndicators[entry.Format.toUpperCase()].UCSites.some(
                (UCSite) => entry.UC === UCSite.UC_ORACLE && entry.Site === UCSite.SITE_ORACLE
              )
              CROk = this.validIndicators[entry.Format.toUpperCase()].CRs.some((CR) => entry.Rayon === CR.CR.toString())
            }
            const amountOk = !isNaN(entry.Montant)
            const isAmountZero = this.families.find(
              (f) =>
                f.code.normalize() === entry.CodeRubrique.normalize() &&
                f.formatType.normalize() === entry.Format.normalize() &&
                f.isAmountZero === true
            )

            if (amountOk && isAmountZero) {
              allAmountZero[isAmountZero.code.normalize()] =
                allAmountZero && allAmountZero[isAmountZero.code.normalize()]
                  ? allAmountZero[isAmountZero.code.normalize()] + entry.Montant
                  : entry.Montant
            }
            if (!formatOk) this.errorMessages.push(this.$t('restitution.error.format', { line: index + 1 }))
            if (!familyOk) this.errorMessages.push(this.$t('restitution.error.family', { line: index + 1 }))
            if (!UCSiteOk) this.errorMessages.push(this.$t('restitution.error.UCSite', { line: index + 1 }))
            if (!CROk) this.errorMessages.push(this.$t('restitution.error.CR', { line: index + 1 }))
            if (!amountOk) this.errorMessages.push(this.$t('restitution.error.amount', { line: index + 1 }))
          } else {
            this.errorMessages.push(this.$t('restitution.error.lineInError', { line: index + 1 }))
          }
        })
        const amountZeroOk = []
        this.loadingMessage = 'Importation du fichier...'
        for (const [key, value] of Object.entries(allAmountZero)) {
          if (value !== 0) amountZeroOk.push(key)
        }
        if (amountZeroOk.length)
          this.errorMessages.push('Les familles suivantes doivent avoir la somme des montants égal à zéro : ' + amountZeroOk.join(', '))

        if (this.errorMessages.length) {
          this.showErrorModal = true
          //Vue.toasted.error(`Erreur lors de l'enregistrement du fichier</br> ${this.errorMessages.join()}`)
        } else {
          this.loadingMessage = 'Sauvegarde du fichier...'
          const formattedEntries = entries.map((e) => {
            ;``
            return {
              accountingUnit: e.UC,
              format: e.Format,
              site: this.validIndicators[e.Format.toUpperCase()].UCSites.find((UCSite) => e.UC === UCSite.UC_ORACLE && e.Site === UCSite.SITE_ORACLE)
                .SITE_ORACLE,
              resultCenter: parseInt(e.Rayon),
              label: e.Libelle,
              amount: parseFloat(e.Montant),
              inputDate: this.today,
              imputationDate: e.ImputationDate,
              family: e.CodeRubrique,
              familyAccount: '0002-INVENTAIRE',
              familyRubric: e.CodeRubrique,
              user: this.$userInfos.uid,
              entryType: fileName
            }
          })

          this.haveToDelete
            ? await this.deleteAndInsertEntries({ entries: formattedEntries, file_name: this.fileName })
            : await this.insertEntries(formattedEntries)
        }
        this.loading = false
        await this.reloadDataGrid()
      },

      filteredFamiliesByType(familyArray, filterParam) {
        return familyArray.filter((family) => family.account === filterParam)
      },

      downloadCSVTemplate() {
        let csv = 'CodeRubrique;Libelle;UC;Site;Rayon;Montant;Format;ImputationDate\n'
        let csvDataExample = 'M_DemConPV;Exemple Commentaire;DEFAULT;1001;10;800;SUPER;01/12/2022\n'
        csv += csvDataExample
        const anchor = document.createElement('a')
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        anchor.target = '_blank'
        anchor.download = `saisie_inventaire_${this.date.toFormat('dd_MM_yyyy_hh_mm_ss')}.csv`
        anchor.click()
      },

      getInventoryDateByParam(site, inventoryDate = null) {
        let filteredInventories = this.inventoriesDates.filter((inventory) => inventory.site === site)
        if (inventoryDate) {
          this.inventoriesList = filteredInventories.filter((inventory) => inventory.inventoryDate.seconds === parseInt(inventoryDate))
        } else {
          this.inventoriesList = filteredInventories
        }

        const newInventoriesList = []
        filteredInventories.map((inv) => {
          const dateValue = this.formatDate(new Date(inv.inventoryDate.seconds * 1000))
          const invDateLabel = `${dateValue} - ${inv.inventoryLabel}`
          const obj = { label: invDateLabel, date: inv.inventoryDate.seconds }
          if (!JSON.stringify(newInventoriesList).includes(JSON.stringify(obj))) {
            newInventoriesList.push(obj)
          }
        })
        this.selectedSiteInventoriesDates = newInventoriesList.sort()
      },

      padTo2Digits(num) {
        return num.toString().padStart(2, '0')
      },

      formatDate(date) {
        return [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/')
      },

      onShowModalInventoryValidation() {
        this.showModalInventoryValidation = true
      },
      async onShowModalInventoryHistory() {
        this.showModalInventoryHistory = true
      },

      checkProperties(obj) {
        for (let key in obj) {
          if (obj[key] !== null && obj[key] != '') return true
        }
        return false
      }
    },
    validations: {
      entries: {
        $each: {
          accountingUnit: {
            isValid: function (value, entry) {
              return this.validAccountingUnits(entry.siteId).includes(value)
            }
          },
          siteId: {
            isValid: function (value, entry) {
              return this.validSiteIds(entry.accountingUnit).includes(value)
            }
          },
          resultCenterId: {
            isValid: function (value) {
              return this.validResultCenterIds().includes(value)
            }
          },
          amount: {
            isValid: function (value, entry) {
              return !isNaN(Number(entry.amount))
            }
          },
          label: {
            required
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .input-file {
    display: none;
  }

  .select-family > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    h2 {
      flex: 1;
    }

    select {
      border: 1px solid #707070;
      border-radius: 3px;
      padding: 5px 8px;
    }
    select:focus {
      outline: none;
    }
  }

  h2 {
    @extend %font-bold;
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
  }

  .buttons {
    position: absolute;
    display: flex;
    margin-bottom: 4rem;

    &-align-right {
      flex-direction: row;
      top: 6.4rem;
      right: 6.4rem;
    }
  }

  .entry {
    overflow: hidden;
    position: relative;

    .content {
      display: flex;
      position: absolute;
      transition: 500ms;
      left: 6.4rem;

      &.family-selected {
        left: calc(-50vw + 6.4rem);
      }
    }

    .family-choice {
      width: 50vw;
      padding-right: 8rem;

      .families {
        max-height: calc(100vh - 21rem);
        overflow-y: scroll;

        button.family {
          display: flex;
          align-items: center;
          cursor: pointer;
          height: 6.4rem;
          width: 98%;
          border-radius: 16px;
          margin-bottom: 2rem;
          background-color: $white;
          outline: none;
          border: 1px solid $dark-grey;

          .code {
            @extend %font-bold;
            width: 20rem;
          }
        }
        button.family-Stock {
          display: flex;
          align-items: center;
          cursor: pointer;
          height: 6.4rem;
          width: 98%;
          border-radius: 16px;
          margin-bottom: 2rem;
          background-color: $light-grey;
          outline: none;
          border: 1px solid $dark-grey;

          .code {
            @extend %font-bold;
            width: 20rem;
          }
        }
      }
    }

    .entries {
      max-height: calc(100vh - 13rem);
      width: calc(100vw - #{$sidebar-width});
      overflow-y: scroll;

      &.disabled {
        opacity: 0.5;

        * {
          cursor: not-allowed;
        }
      }

      .details {
        height: 6em;

        div {
          margin-bottom: 0.4rem;
        }

        .label {
          @extend %font-bold;
        }

        .error-message {
          color: red;
        }
      }

      .grid {
        /*max-height: calc(100% - 250px);*/
        overflow: auto;
        margin: 3.2rem 0;
        display: grid;
        width: 100%;
        margin-top: 4rem;
        grid-template-columns: 70px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        .cell {
          height: 4.8rem;
          @include flex(center, flex-start);
          position: sticky;
          top: 0;
          outline: none;

          &:not(.header) {
            border-bottom: 1px solid $light-grey;
          }
          &:nth-last-child(-n + 8) {
            border: none;
          }

          input {
            border: transparent;
            width: 80%;
            background: none;
            outline: none;
            height: 3.2rem;

            &.active-line {
              border-bottom: 1px solid $light-grey;
            }

            &:focus {
              border-bottom: 2px solid $purple;
            }

            &.error,
            &:not(:valid) {
              border-bottom: 2px solid red;
            }
          }

          .error-message {
            position: absolute;
            font-size: 13px;
            bottom: -1em;
            width: auto;
          }
        }

        .header {
          @extend %font-bold;
          z-index: 2;
          font-size: 2rem;
          background-color: $background;
        }
      }
    }
  }

  .locked-entry {
    font-size: 16px;
    font-weight: bold;
  }

  .extourne {
    display: flex;
    align-items: center;
  }

  .link-super {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 6.4rem;
    width: 98%;
    border-radius: 16px;
    margin-bottom: 2rem;
    background-color: $white;
    outline: none;
    border: 1px solid $dark-grey;
  }

  .details {
    height: 8em;

    div {
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
    }

    .label {
      @extend %font-bold;
    }

    .error-message {
      color: red;
    }
  }

  .details-model {
    height: 5em;

    div {
      margin-bottom: 0.4rem;
    }

    .label {
      @extend %font-bold;
    }

    .error-message {
      color: red;
    }
  }

  .locked-entry {
    font-size: 16px;
    font-weight: bold;
  }

  .extourne {
    display: flex;
    align-items: center;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8f6c9f;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #8f6c9f;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #8f6c9f;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .small.green {
    background: #58e8ab !important;
  }
</style>
